<template>
  <section :class="className" class="form-info-box">
    <div v-for="(item, index) in value" :key="index" class="mb-4 form-info-item">
      <span>{{ item.text }}: </span><span>{{ item.value }}</span>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
    },
    hasBorder: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    data: null,
  }),
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.data = this.$eagleLodash.cloneDeep(this.value);
      },
    },
    data: {
      deep: true,
      handler() {
        if (this.$eagleLodash.isEqual(this.data, this.value)) return;
        this.$emit("input", this.data);
      },
    },
  },
  computed: {
    className() {
      if(this.hasBorder) return 'border-maxLightGray-all pa-3'
      return ''
    },
  },
};
</script>
<style lang="sass">
.form-info-box
  border-radius: 5px
  .form-info-item
    &:last-child
      margin-bottom: 0 !important

</style>